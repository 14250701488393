import 'bootstrap/dist/css/bootstrap.min.css';
import React, { useState, useEffect } from 'react';

function App() {
  const [tableData, setTableData] = useState({ rows: [], criteria: [] });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [judge, setJudge] = useState(1);
  const [instrument, setInstrument] = useState("O");
  const [event, setEvent] = useState("1");

  useEffect(() => {
    const url = `https://django-server-production-6446.up.railway.app/api/scores/?format=json&judge=${judge}&instrument=${instrument}&event=${event}`;
    console.log(url);
    fetch(url)  // Adjust URL as needed
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(data => {
        const transformedData = transformData(data);
        setTableData(transformedData);
        setLoading(false);
      })
      .catch(error => {
        setError(error.message);
        setLoading(false);
      });
  }, [judge, instrument, event]);

  const transformData = (data) => {
    const criteriaSet = new Set();
    const recordsMap = new Map();
    const scoreIdMap = new Map();

    data.forEach(({ id, audition_record, criterion, score }) => {
      criteriaSet.add(criterion.category);
      const recordKey = audition_record.number;

      if (!recordsMap.has(recordKey)) {
        recordsMap.set(recordKey, { auditionRecordNumber: recordKey, scores: {} });
        scoreIdMap.set(recordKey, {});
      }

      recordsMap.get(recordKey).scores[criterion.category] = score;
      scoreIdMap.get(recordKey)[criterion.category] = id;
    });

    return {
      rows: Array.from(recordsMap.values()),
      criteria: Array.from(criteriaSet),
      scoreIdMap: scoreIdMap
    };
  };

  const handleScoreChange = (auditionRecordNumber, criterion, newScore) => {
    // First, update the score locally
    setTableData(prevData => {
      const updatedRows = prevData.rows.map(row => {
        if (row.auditionRecordNumber === auditionRecordNumber) {
          return {
            ...row,
            scores: {
              ...row.scores,
              [criterion]: parseFloat(newScore)
            }
          };
        }
        return row;
      });

      return { ...prevData, rows: updatedRows };
    });

    // Find the ID of the score to be updated
    const scoreId = findScoreId(auditionRecordNumber, criterion);

    // Then, send the PATCH request to update the score in the database
    fetch(`https://django-server-production-6446.up.railway.app/api/scores/${scoreId}/`, {  // Adjust API URL as needed
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        // Include any necessary authentication headers
      },
      body: JSON.stringify({ score: parseFloat(newScore) }),
    })
      .then(response => {
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        return response.json();
      })
      .then(data => {
        console.log('Score updated:', data);
      })
      .catch((error) => {
        console.error('Error updating score:', error);
      });
  };

  const findScoreId = (auditionRecordNumber, criterion) => {
    if (tableData.scoreIdMap.has(auditionRecordNumber)) {
      return tableData.scoreIdMap.get(auditionRecordNumber)[criterion];
    }
    return null; // Or handle the case where the ID is not found
  };



  if (loading) {
    console.log('Loading...');
  }

  if (error) {
    console.log('Error.');
  }

  return (
    <>
      <div className="container mt-4">
        <div className="row">
          <div className="col-md-4 mb-3">
            <div className="form-group">
              <label htmlFor="event">Event Code:</label>
              <input
                type="text"
                className="form-control"
                id="event"
                value={event}
                onChange={(e) => setEvent(e.target.value)}
                placeholder="Enter event code"
              />
            </div>
          </div>


          <div className="col-md-4 mb-3">
            <div className="form-group">
              <label htmlFor="judge">Judge:</label>
              <select onChange={(e) => setJudge(e.target.value)} value={judge} className="form-control" id="judge">
                <option value="1">Judge 1</option>
                <option value="2">Judge 2</option>
                <option value="3">Judge 3</option>
                <option value="4">Judge 4</option>
                <option value="SC">Scales</option>
                <option value="SR">Sight Reading</option>
              </select>
            </div>
          </div>

          <div className="col-md-4 mb-3">
            <div className="form-group">
              <label htmlFor="instrument">Instrument:</label>
              <select
                onChange={(e) => setInstrument(e.target.value)}
                value={instrument}
                className="form-control"
                id="instrument"
              >

                <option value="F">Flute</option>
                <option value="O">Oboe</option>
                <option value="B">Bassoon</option>
                <option value="C">Clarinet</option>
                <option value="BC">Bass Clarinet</option>
                <option value="AS">Alto Saxophone</option>
                <option value="TS">Tenor Saxophone</option>
                <option value="BS">Baritone Saxophone</option>
                <option value="T">Trumpet</option>
                <option value="H">Horn</option>
                <option value="TB">Trombone</option>
                <option value="EU">Euphonium</option>
                <option value="TU">Tuba</option>
                <option value="SN">Snare</option>
                <option value="TI">Timpani</option>
                <option value="MA">Mallets</option>
              </select>
            </div>
          </div>
        </div>



        <table className="table table-bordered table-hover">
          <thead className='thead-light'>
            <tr>
              <th>Audition Record Number</th>
              {tableData.criteria.map((criterion, index) => (
                <th key={index}>{criterion}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.rows.map((row, rowIndex) => (
              <tr key={rowIndex}>
                <td>{row.auditionRecordNumber}</td>
                {tableData.criteria.map((criterion, cellIndex) => {
                  const scoreValue = row.scores ? row.scores[criterion] : '';
                  return (
                    <td key={cellIndex}>
                      <input
                        type="number"
                        value={scoreValue}
                        onChange={(e) => handleScoreChange(row.auditionRecordNumber, criterion, e.target.value)}
                        className="form-control"
                      />
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>

        </table>
      </div>
    </>
  );
}


export default App;

